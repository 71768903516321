import React from 'react'
import footerLogo from '../assets/OmniSTAN_footer.svg'

const Footer = () => {
  return (
    <div className='w-screen lg:px-40 px-[4%] py-11 bg-EarthGreen-700 flex gap-8 flex-col text-BrandGreen-300 lg:text-lg'>
      <div className='flex lg:flex-row lg:items-start text-center items-center flex-col justify-between lg:gap-6 gap-8 '>
        <div className='lg:text-2xl text-xl font-bold'>
          <img src={footerLogo} alt='' />
        </div>
        {/* <p className='hidden'>info@omnistan.com | Terms & Conditions | Privacy policy</p> */}
        <p className='flex flex-col lg:flex-row lg:justify-between lg:gap-1 gap-2 tracking-[-0.025rem] items-center leading-[150%]'>
          <span className='lg:text-base text-[0.8125rem] lg:font-light font-normal'>
            {' '}
            info@omnistan.com
          </span>
          <span className='lg:flex hidden items-center'>|</span>
          <span className=' lg:text-base text-[0.8125rem] lg:font-light font-normal '>
            Terms & Conditions
          </span>
          <span className='lg:flex hidden items-center'>|</span>
          <span className='lg:text-base text-[0.8125rem] lg:font-light font-normal'>
            Privacy policy
          </span>
        </p>
      </div>
      <hr className='border-EarthGreen-300' />
      <div className='flex lg:flex-row lg:items-start flex-col items-center text-center lg:text-left justify-between tracking-[-0.025rem] leading-[150%] gap-2'>
        <p className='lg:font-light font-normal leading-[150%] tracking-[-0.02031rem] lg:text-lg text-[0.8125rem]'>
          ©️ 2023 OmniSTAN Ltd. All Rights Reserved
        </p>
        <p className=' lg:font-light font-normal leading-[150%] tracking-[-0.02031rem] lg:text-lg text-[0.8125rem]'>
          Powered by{' '}
          <a
            rel='noreferrer'
            href={
              'https://omnistrategies.net/'
            }
            target='_blank'
          >
            <span className='underline'>Omni Strategies</span>
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
