import * as Yup from 'yup';

export const requestDemoSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    full_name: Yup.string().required("Full name is required"),
    organization: Yup.string().required("Organization's name is required"),
    email: Yup.string().required("Email is required"),
    purpose: Yup.string().required("Enter purpose"),
    phone_number: Yup.string().required("Phone number is required"),
    industry: Yup.string().required("Industy's name is required"),
})

export const contactUsSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    full_name: Yup.string().required("Full name is required"),
    organization: Yup.string().required("Organization's name is required"),
    email: Yup.string().required("Email is required"),
    purpose: Yup.string().required("Enter purpose"),
    phone_number: Yup.string(),
    industry: Yup.string(),
})