import { useState } from 'react'
import Navbar from './components/Navbar'
import Home from './pages/Home'
import Modal from './components/modal'
import { RequestDemoForm, ContactUsForm } from './utils/Forms'

function App () {
  const [open, setOpen] = useState(false)
  const [openContactForm, setContactForm] = useState(false)
  return (
    <div className='overflow-x-hidden'>
      {Modal({ formDetails: RequestDemoForm, open: open, setOpen: setOpen })}
      {Modal({
        formDetails: ContactUsForm,
        open: openContactForm,
        setOpen: setContactForm
      })}
      <Navbar
        open={open}
        setOpen={setOpen}
        contactOpen={openContactForm}
        setContactOpen={setContactForm}
      />
      <Home
        open={open}
        setOpen={setOpen}
        contactOpen={openContactForm}
        setContactOpen={setContactForm}
      />
    </div>
  )
}

export default App
