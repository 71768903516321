import React from 'react'
import heroImg from '../assets/Hero-Image.svg'
import bckgrdImg from '../assets/herobackground.png'

const Hero = ({ open, setOpen }) => {
  return (
    <div
      id='hero'
      style={{ backgroundImage: `url(${bckgrdImg})` }}
      className='h-[85vh] mt-[15vh] flex flex-col px-[4%]  justify-center '
    >
      <div className=' flex lg:flex-row flex-col lg:mt-12 mt-8  lg:justify-between justify-center'>
        <div className='flex flex-col lg:items-start justify-center lg:text-left text-center max-w-[1240px] lg:w-[57%] w-full gap-8'>
          <div className='flex flex-col justify-center md:items-start w-full gap-4 '>
            <div className='lg:text-3xl xl:text-5xl md:text-2xl text-xl font-semibold lg:px-8 lg:leading-[120%] leading-[35px] lg:tracking-tighter tracking-[ -0.03906rem]'>
              <p className='text-BrandGreen-900'>
                Generate
                <span className='text-BrandGreen-400'> Location-Specific </span>
                Data with Africa's First Digital Property Addressing System
              </p>
            </div>
            <div className=''>
              <p className='lg:text-lg text-base leading-[1.5rem] tracking-[-0.025rem] lg:w-full lg:px-8 font-light text-neutral-800 xl:text-xl xl:w-[83%]'>
                OmniSTAN is integrated with satelite imagery to allow you
                uniquely identify property based on geospacial data.
              </p>
            </div>
          </div>

          {/* Buttons */}
          <div className='flex flex-row lg:px-8 px-4 lg:justify-start justify-center '>
            <button
              onClick={() => {
                setOpen(!open)
              }}
              className='bg-gradient-to-r from-BrandGreen-800 to-BrandGreen-500 border-BrandGreen-800 text-Neutral-0 rounded-lg px-8 py-2 lg:text-base text-sm h-12 font-medium'
            >
              Request a Demo
            </button>
            <button className='px-8 py-2 mx-3 hidden md:flex bg-Neutral-0 text-BrandGreen-800 lg:text-base text-sm font-medium '>
              Learn More
            </button>
          </div>
        </div>

        {/* Hero Image */}
        <div className='flex justify-center items-center lg:w-[760px] lg:h-[512px] lg:mb-0 w-full '>
          <img style={{ position: 'relative' }} src={heroImg} alt='/' />
        </div>
      </div>
    </div>
  )
}

export default Hero
