import React from 'react'
import Partners from '../components/Partners'
import About from '../components/About'
import Carousel from '../components/Carousel'
import ContactUs from '../components/ContactUs'
import Features from '../components/Features'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import FAQs from '../components/FAQs'

const Home = ({open, setOpen, contactOpen, setContactOpen}) => {
  return (
    <div className='flex flex-col justify-center items-center'>
      <Hero open={open} setOpen={setOpen} />
      <About  />
      <Carousel  />
      <Features  open={open} setOpen={setOpen} />
      <Partners  />
      <FAQs />
      <ContactUs open={contactOpen} setOpen={setContactOpen} />
      <Footer />
    </div>
  )
}

export default Home
