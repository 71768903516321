import React, { useState } from 'react'
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import greenBackground from '../assets/greenBackground.png'
import { useFormik } from 'formik'
import logo from '../assets/OmniSTAN.svg'
import CustomInput from './CustomInput'
import { CountryCodes } from '../utils/CountryCodes'
import { contactUsSchema, requestDemoSchema } from '../utils/ValidationsSchemas'

const Modal = ({ formDetails, open, setOpen }) => {
  const cancelButtonRef = useRef(null)
  const [selectedCode, setSelectedCode] = useState("+233")


  // const webhookURL =
  //   'https://akofisgroupoutlook.webhook.office.com/webhookb2/2f39d9e4-3340-41f9-b23b-441ea7e10c66@2f9f1cc3-f472-49d9-841e-1e2c5f64327f/IncomingWebhook/19c6c02b8fc74e0e871d7673cd74549a/93e03d5a-5ae1-4018-a502-7be40fa06f98'

  const formik = useFormik({
    initialValues: {
      title: formDetails.title,
      full_name: "",
      organization: "",
      email: "",
      purpose: "",
      phone_number: "",
      industry: ""
    },
    validateOnChange: true,
    validationSchema: formDetails.title === 'Request a Demo'?requestDemoSchema:contactUsSchema,
    onSubmit: values => {
      const url = prepareEmail(values)
      window.location.href = url
      // postMessageToTeams('Test', 'Hello World')
    }
  })

  const prepareEmail = (values) =>{
    let url ="mailto:info@omnistrategies.net?"
      url += `subject=${encodeURIComponent(values.title)}&`
      url += `body=${encodeURIComponent("Hello")}%2C%0A%0A`
      url += `${encodeURIComponent(values.purpose)}%0A%0A`
      url += `${encodeURIComponent(values.full_name)}%2C%0A`
      url += `${encodeURIComponent(values.email)}%2C%0A`
      if(formDetails.title === 'Request a Demo'){
        url += `${encodeURIComponent(selectedCode+values.phone_number)}%2C%0A`
      }
      if(formDetails.title === 'Request a Demo'){
        url += `${encodeURIComponent(values.industry)}%2C%0A`
      }
      url += `${encodeURIComponent(values.organization)}%2E%0A`
      return url
  }

  // async function postMessageToTeams(title, message) {
  //   const card = {
  //     type: 'message',
  //     attachments: [
  //       {
  //         contentType: 'application/vnd.microsoft.card.adaptive',
  //         contentUrl: null,
  //         content: {
  //           $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
  //           type: 'AdaptiveCard',
  //           version: '1.2',
  //           body: [
  //             {
  //               type: 'TextBlock',
  //               text: 'For Samples and Templates, see [https://adaptivecards.io/samples](https://adaptivecards.io/samples)'
  //             }
  //           ]
  //         }
  //       }
  //     ]
  //   }

  //   try {
  //     const response = await axios.post(webhookURL, card, {
  //       withCredentials: true,
  //       headers: {
  //         'content-type': 'application/vnd.microsoft.card.adaptive'
  //       }
  //     })
  //     return `${response.status} - ${response.statusText}`
  //   } catch (err) {
  //     return err
  //   }
  // }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='z-10'
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          style={{ backgroundImage: `url(${greenBackground})` }}
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
        </Transition.Child>

        <div className='fixed inset-0 z-0 overflow-y-auto'>
          <div className='absolute w-full flex flex-row justify-between lg:px-32 px-5 lg:mt-36 lg:mb-8 mt-28'>
            <div className='flex items-center'>
              <img src={logo} className='w-28' alt='/' />
            </div>
            <div>
              <h1 className='font-semibold lg:text-[49px] text-[18px] '>
                {formDetails.title}
              </h1>
            </div>
            <div>
              <XIcon
                onClick={() => setOpen(false)}
                className='lg:w-[66px] w-5'
              />
            </div>
          </div>
          <div className='flex flex-col min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className={formDetails.dialogClasses}>
                <div className='bg-white px-4 pt-5 pb-4 lg:mt-0'>
                  <form onSubmit={formik.handleSubmit}>
                    <div className={formDetails.parentStyle}>
                      {formDetails.title === 'Request a Demo' ? (
                        <>
                          {formDetails.formContent.map((field, key) => {
                            return (
                              <div key={key} className={field.className}>
                                <label
                                  htmlFor={field.name}
                                  className='block text-sm font-medium text-gray-700'
                                >
                                  {field.label}
                                </label>
                                {field.type === 'text' &&
                                field.name === 'phone_number' ? (
                                  <div className='relative flex flex-col items-start'>
                                    <div className='absolute flex items-center'>
                                      <label
                                        htmlFor='currency'
                                        className='sr-only'
                                      >
                                        Currency
                                      </label>
                                      <select
                                        id='country_code'
                                        name='country_code'
                                        className='rounded-lg border-0 bg-transparent w-32  focus:ring-2 focus:ring-inset focus:ring-BrandGreen-600 sm:text-sm'
                                        value={selectedCode}
                                        onChange={(e)=>setSelectedCode(e.target.value)}
                                      >
                                       {CountryCodes.map((code, key) => (
                                        <option className='text-sm md:text-base' key={key} value={code.dial_code}>
                                          {code.name}
                                        </option>
                                       ))}
                                      </select>
                                    </div>
                                    <CustomInput
                                      type={field.type}
                                      name={field.name}
                                      id={field.name}
                                      autoComplete={field.name}
                                      className={field.fieldClassName}
                                      onChange={formik.handleChange}
                                      error={formik.errors[field.name]}
                                    />
                                  </div>
                                ) : field.type === 'text' ? (
                                  <>
                                  <input
                                    type={field.type}
                                    name={field.name}
                                    id={field.name}
                                    autoComplete={field.name}
                                    className={field.fieldClassName}
                                    onChange={formik.handleChange}
                                  />
                                  <p className='text-red-500 text-xs'>{formik.errors[field.name]}</p>
                                  </>
                                ) : (
                                  <>
                                  <textarea
                                    id={field.name}
                                    name={field.name}
                                    rows={3}
                                    className={field.fieldClassName}
                                    placeholder='Tell us why you’re reaching out...'
                                    onChange={formik.handleChange}
                                    />
                                    <p className='text-red-500 text-xs -mt-4 mb-4'>{formik.errors.purpose}</p>
                                    </>
                                )}
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          {formDetails.formContent.map((field, key) => {
                            return (
                              <div key={key} className='mb-5'>
                                <div>
                                  <label
                                    htmlFor={field.name}
                                    className='block text-sm font-medium text-gray-700'
                                  >
                                    {field.label}
                                  </label>
                                  <div className={field.className}>
                                    {field.type === 'textArea' ? (
                                      <>
                                      <textarea
                                        id={field.name}
                                        name={field.name}
                                        rows={3}
                                        className={field.fieldClassName}
                                        placeholder='Tell us why you’re reaching out...'
                                        onChange={formik.handleChange}
                                        />
                                        <p className='text-red-500 text-xs'>{formik.errors[field.name]}</p>
                                        </>
                                    ) : (
                                      <>
                                      <input
                                        type={field.type}
                                        name={field.name}
                                        id={field.name}
                                        className={field.fieldClassName}
                                        onChange={formik.handleChange}
                                        />
                                        <p className='text-red-500 text-xs'>{formik.errors[field.name]}</p>
                                        </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </div>
                    <div className='md:flex w-[100%]'>
                      <button
                        type='submit'
                        className='bg-gradient-to-r from-BrandGreen-800 to-BrandGreen-500 border-BrandGreen-800 text-Neutral-0 rounded-lg px-8 py-3 w-full'
                      >
                        Send Request
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() => setOpen(false)}
                  >
                    Deactivate
                  </button>
                  <button
                    type='button'
                    className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
