import React from 'react'
import planetImg from '../assets/Planet.svg';
import cBrainImg from '../assets/cBain.svg';

const Partners = () => {
  return (
    <div className='w-screen bg-EarthBlue-900 items-center lg:justify-between justify-center flex lg:flex-row flex-col gap-4 xl:px-28 lg:px-20 px-8 h-[25.5rem]'>
      <div className='flex flex-col items-center  lg:items-start gap-4 lg:mb-0'>
        <h1 className='text-EarthBlue-100 text-xl lg:text-3xl xl:text-4xl font-semibold leading-[120%] tracking-[-0.06094rem]'>
          Our Partners
        </h1>
        <p className='text-EarthBlue-600 text-[0.8125rem] lg:text-lg xl:text-xl tracking-[-0.02031rem] lg:tracking-[-0.03125rem] font-light text-center lg:leading-[120%] leading-[150%] lg:text-left'>These trusted brands enable us to do more for you.</p>
      </div>
      <div className='flex flex-row justify-between items-center px-5'>
        <div className='lg:mr-7 mr-2'>
            <img src={planetImg} alt="/" />
        </div>
        <div className='lg:ml-0 ml-2'>
            <img src={cBrainImg} alt="/" />
        </div>
      </div>
    </div>
  )
}

export default Partners
