import React from 'react'
import { CheckIcon } from '@heroicons/react/solid'
import topoIllustration from '../assets/topoIllustration.png'

const Features = ({ open, setOpen }) => {
  const features = [
    'Identify properties and generate addresses',
    'Search for and validate addresses',
    'Print and install address plates',
    'Update address information'
  ]

  return (
    <div id='features' className='flex flex-col w-screen'>
      <div className='flex lg:flex-row flex-col items-center xl:px-32 lg:px-24 px-[1.25rem] lg:mt-20 mt-14 lg:mb-16 mb-8 lg:gap-28'>
        <div
          style={{ backgroundImage: `url(${topoIllustration})` }}
          className='lg:w-[50%] w-[20.9375rem] h-[20.9375rem] object-scale-down lg:mb-5 lg:h-[32rem] rounded-lg border-2 border-BrandGreen-100'
        ></div>
        <div className='flex flex-col min-h-[32rem] lg:w-[50%] w-full mt-4 lg:mt-0 '>
          <h3 className='lg:text-3xl xl:text-4xl text-xl font-semibold mb-3 text-neutral-900 lg:leading-[120%] tracking-[-0.06094rem] xl:pr-10 lg:text-left text-center'>
            A Unique Approach to Property Identification
          </h3>
          <p className='lg:text-base xl:text-lg xl:pr-10 text-sm lg:font-light font-normal leading-[150%] tracking-[-0.02031rem] lg:leading-6 text-neutral-800 mb-4 lg:text-left text-center'>
            OmniSTAN uses geospatial technology to provide a repository of
            comprehensive property data that organisations and entities can
            interact with to successfully meet their goals.
          </p>
          <div className='text-BrandGreen-900 flex flex-col gap-6 relative mb-8 lg:mt-0 mt-4'>
            {features.map((feature, key) => {
              return (
                <div key={key} className='flex flex-row items-center'>
                  <div className='flex text-center items-center justify-center w-9 h-9 mr-6 rounded-2xl bg-BrandGreen-100'>
                    <CheckIcon className='h-8 text-BrandGreen-600' />
                  </div>
                  <p className='lg:text-base font-medium text-sm text-BrandGreen-800'>
                    {feature}
                  </p>
                </div>
              )
            })}
          </div>
          <div className='flex flex-row items-start lg:justify-start justify-center'>
            <button
              onClick={() => {
                setOpen(!open)
              }}
              className='bg-gradient-to-r from-BrandGreen-800 to-BrandGreen-500 border-BrandGreen-800 text-Neutral-0 rounded-lg px-8 py-2 text-base h-14 font-medium w-full lg:w-[25%]'
            >
              Request a Demo
            </button>
          </div>
        </div>
      </div>

      <div className='w-screen bg-EarthBlue-100 py-20 lg:px-20 xl:px-28 lg:text-left text-center'>
        <h1 className='lg:text-3xl xl:text-4xl lg:leading-[120%] tracking-[-0.03906rem] text-xl font-semibold mb-4 lg:px-0 text-EarthBlue-900'>
          Over <span className='text-EarthBlue-600'>8 Million</span> Properties
          Identified and Validated
        </h1>
        <p className='lg:text-lg text-[0.8125rem] lg:px-0 text-EarthBlue-800 lg:font-light font-normal leading-[150%] tracking-[-0.02031rem]'>
          OmniSTAN has been trusted by government agencies and businesses to
          ensure efficiency in their operations.
        </p>
      </div>
    </div>
  )
}

export default Features
