import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const FAQs = () => {
  const questions = [
    {
      question: 'What is OmniSTAN and how does it work?',
      answer: `OmniSTAN is a B2B solution that enables companies to quickly and securely launch entire environments in minutes. It uses Terraform, an automated framework, to reduce manual labour and maximise efficiency while integrating satellite imagery to allow users uniquely identify property based on geospatial data. 
      It can be customised to meet the specific needs of each business.`
    },
    {
      question: 'Who can use OmniSTAN?',
      answer: `OmniSTAN is suitable for any organisation, private or public. 
      The scalability and agnostic nature of the platform mean that organisations can tailor it to suit their specific needs.`
    },
    {
      question: 'How do I get started with OmniSTAN?',
      answer: `Click 'Request a Demo' and
      our team will get you a custom plan that covers everything you need.`
    }
  ]

  return (
    <div
      id='faqs'
      className='w-screen flex flex-col h-[40.8125rem] lg:px-48 justify-center text-center items-center'
    >
      <h1 className=' lg:text-3xl xl:text-4xl tracking-[-0.03906rem] lg:tracking-[-0.06094rem] text-xl text-EarthYellow-900 lg:mt-0 mt-44 font-semibold leading-[120%]'>
        Frequently Asked Questions
      </h1>

      <div className='lg:w-[820px] lg:px-4'>
        <div className='mx-auto w-full rounded-2xl bg-white px-[4%]'>
          {questions.map((question, key) => {
            return (
              <Disclosure
                key={key}
                as='div'
                className='border-b-2 py-5 text-BrandGreen-900'
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button className='flex w-full justify-between rounded-lg  px-4 py-2'>
                      <span className='lg:text-xl xl:text-2xl text-neutral-800 tracking-[-0.06094rem] leading-[120%] font-medium text-[20px]'>
                        {question.question}
                      </span>

                      <ChevronDownIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        }  w-10`}
                      />
                    </Disclosure.Button>
                    <Transition
                      enter='transition duration-2000 ease-out'
                      enterFrom='transform scale-95 opacity-0'
                      enterTo='transform scale-100 opacity-100'
                      leave='transition duration-1000 ease-out'
                      leaveFrom='transform scale-100 opacity-100'
                      leaveTo='transform scale-95 opacity-0'
                    >
                      <Disclosure.Panel className='px-10 py-2 text-md text-left text-gray-500'>
                        {question.answer}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FAQs
