import React, { useState } from 'react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
// import { BriefcaseIcon, AcademicCapIcon, CurrencyDollarIcon, LibraryIcon, BeakerIcon } from '@heroicons/react/solid'

import business from '../assets/businesses.svg'
import education from '../assets/education.svg'
import finance from '../assets/finance.svg'
import government from '../assets/government.svg'
import health from '../assets/health.svg'
import './styles.css'

const Carousel = ({ open, setOpen }) => {
  const slides = [
    {
      url: business,
      title: 'OmniSTAN for Businesses',
      description: `We make it easier for you to do business, with improvements to customer identification for KYC and location logistics for e-commerce.`
    },
    {
      url: education,
      title: 'OmniSTAN for Education',
      description: `
      We improve accuracy of student data collection.
      `
    },
    {
      url: finance,
      title: 'OmniSTAN for Finance',
      description: `
      We make it easier to validate customer addresses.
      `
    },

    {
      url: government,
      title: 'OmniSTAN for Governments',
      description: `
      We help governments build a comprehensive national database of properties, and effectively plan developmental projects.
      `
    },
    {
      url: health,
      title: 'OmniSTAN for Medicine',
      description: `
      We make it easier for emergency services to locate addresses, especially in rural areas.
      `
    }
  ]

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged (slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created () {
      setLoaded(true)
    }
  })

  return (
    <>
      <div className=' h-[424px] xl:h-[500px] bg-EarthBlue-100 w-screen py-16 px-4 lg:mt-[100px] mt-4'>
        <div ref={sliderRef} className='keen-slider'>
          {slides.map((slide, slideIndex) => {
            return (
              <div
                key={slideIndex}
                className='keen-slider__slide flex flex-col items-center justify-center'
              >
                <img
                  src={slide.url}
                  className='h-[4.5rem] w-[4.5rem]'
                  alt='/'
                />
                <h1 className='lg:mt-[31px] mt-8 leading-[120%] tracking-[-0.03906rem] mb-4 text-center lg:text-left text-xl lg:text-3xl xl:text-4xl font-semibold text-EarthBlue-900'>
                  {slide.title}
                </h1>
                <div className=' lg:w-[50%] h-full'>
                  <p className='text-center lg:font-light font-normal text-[0.8125rem] tracking-[-0.02031rem] lg:text-lg xl:text-xl lg:leading-6 xl:leading-7 text-EarthBlue-800'>
                    {slide.description}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
        {loaded && instanceRef.current && (
          <div className='dots mt-16'>
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys()
            ].map(idx => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx)
                  }}
                  className={'dot' + (currentSlide === idx ? ' active' : '')}
                ></button>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}
export default Carousel
