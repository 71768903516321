import React from 'react'
import greenBackground from '../assets/greenBackground.png'

const About = ({open, setOpen}) => {
  return (
    <div id='about' style={{ backgroundImage: `url(${greenBackground})`}} className='bg-BrandGreen-100 lg:mt-0 mt-12 flex flex-col rounded-xl items-center justify-center px-8 h-96 xl:h-[30rem] border-2 border-BrandGreen-100 lg:mx-32 mx-[4%]'>
      <div className='text-center'>
        <h4 className='lg:text-3xl xl:text-4xl text-xl mb-2 font-semibold text-EarthYellow-900 leading-[120%] tracking-[-0.03906rem]'>Why OmniSTAN?</h4>
      </div>
      <div className='lg:w-[60%] w-[120%] text-center lg:font-light font-normal text-[ 0.8125rem] lg:text-lg xl:text-xl text-BrandGreen-900 leading-[150%] tracking-[-0.02031rem] lg:leading-6 xl:leading-8'>
        <p>
        With unplanned and unstructured settlements making it
          difficult for businesses and governments to provide services and
          distribute resources, OmniSTAN was built to empower developing
          countries to achieve socio-economic freedom.
        </p>

      </div>
    </div>
  )
}

export default About
