import React, { useEffect, useRef } from 'react';

const CustomInput = ({type, name, id, autoComplete, className, onChange, error}) => {
  // Create a ref for the input element
  const inputRef = useRef(null);
 
  useEffect(() => {
    const cursorPosition = 16; // Set the desired cursor position here

    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, []);

  return (
    <>
    <input
      ref={inputRef}
      className={className}
      type={type}
      name={name}
      autoComplete={autoComplete}
      id={id}
      onChange={onChange}
      defaultValue={'                                '} 
      //   onBlur={() => inputRef.current.setSelectionRange(cursorPosition, cursorPosition)}
      />
      <p className='absolute text-red-500 text-xs -bottom-4'>{error}</p>
    </>
  );
};

export default CustomInput;
