import React from 'react'
import greenBackground from '../assets/greenBackground.png'


const ContactUs = ({open, setOpen}) => {
  return (
    <div id='contact' className= 'w-screen flex justify-center items-center lg:px-48 px-[4%] h-[31.8125rem] lg:mb-10 text-lg'>
      <div style={{ backgroundImage: `url(${greenBackground})`}} className='bg-BrandGreen-100 text-center flex flex-col gap-4 w-[1027px] pt-12 rounded-xl text-BrandGreen-900'>
        <h1 className='lg:text-3xl xl:text-4xl text-xl font-semibold text-EarthYellow-900 lg:tracking-[-0.06094rem] tracking-[-0.03906rem] leading-[120%]'>Want to know more?</h1>
        <p className='mb-10 lg:text-lg xl:text-xl text-base text-BrandGreen-900 px-10 lg:px-0 lg:tracking-[-0.06094rem] tracking-[-0.02031rem] lg:leading-[120%] leading-[150%] lg:font-light font-normal'>We’re happy to help you reach your full potential with OmniSTAN.</p>
        <button onClick={() => {setOpen(!open)}}  className='bg-gradient-to-r from-BrandGreen-800 to-BrandGreen-500 border-BrandGreen-800 text-Neutral-0 rounded-bl-xl rounded-br-xl px-8 py-3 font-light'>
            Contact Us
        </button>
      </div>
    </div>
  )
}

export default ContactUs
