export const ContactUsForm = {
    title: 'Contact Us',
    parentStyle: 'flex flex-col',
    dialogClasses: 'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all lg:w-[399px] lg:left-10 -left-10  mt-48',
    formContent: [
        {
            label: "Full Name",
            type: "text",
            name: 'full_name',
            className: 'mt-1 flex flex-col rounded-md shadow-sm',
            fieldClassName: 'w-full flex-1 rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
        },
        {
            label: "Organization",
            type: "text",
            name: "organization",
            className: 'mt-1 flex flex-col rounded-md shadow-sm',
            fieldClassName: 'w-full flex-1 rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
        },
        {
            label: "Email Address",
            type: "email",
            name: "email",
            className: 'mt-1 flex flex-col rounded-md shadow-sm',
            fieldClassName: 'w-full flex-1 rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
        },
        {
            label: "Purpose",
            type: "textArea",
            name: "purpose",
            className: 'mt-1 flex flex-col rounded-md shadow-sm',
            fieldClassName: 'rounded-lg mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
        }
    ]

}


export const RequestDemoForm = {
    title: "Request a Demo",
    parentStyle: 'lg:grid lg:grid-cols-6 lg:gap-6 flex flex-col z-10',
    dialogClasses: 'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all lg:w-[746px] lg:left-10 -left-10 mt-48',
    formContent: [
        {
            label: "Full Name",
            type: "text",
            name: 'full_name',
            className: 'col-span-6',
            fieldClassName: 'w-full flex-1 rounded-lg border-gray-300 focus:ring-BrandGreen-600 focus:ring-BrandGreen-600 lg:mb-0 mb-5'
        },
        {
            label: "Email Address",
            type: "text",
            name: "email",
            className: 'col-span-6 sm:col-span-3',
            fieldClassName: 'w-full flex-1 rounded-lg border-gray-300 focus:ring-BrandGreen-600 focus:ring-BrandGreen-600 lg:mb-0 mb-5'
        },
        {
            label: "Phone Number",
            type: "text",
            name: "phone_number",
            className: 'col-span-6 sm:col-span-3',
            fieldClassName: 'w-full flex-1 rounded-lg border-gray-300 focus:ring-BrandGreen-600 focus:ring-BrandGreen-600 lg:mb-0 mb-5'
        },
        {
            label: "Organization",
            type: "text",
            name: "organization",
            className: 'col-span-6 sm:col-span-3',
            fieldClassName: 'w-full flex-1 rounded-lg border-gray-300 focus:ring-BrandGreen-600 focus:ring-BrandGreen-600 lg:mb-0 mb-5'
        },
        {
            label: "Industry",
            type: "text",
            name: "industry",
            className: 'col-span-6 sm:col-span-3',
            fieldClassName: 'w-full flex-1 rounded-lg border-gray-300 focus:ring-BrandGreen-600 focus:ring-BrandGreen-600 lg:mb-0 mb-5'
        },
        {
            label: "Purpose",
            type: "textArea",
            name: "purpose",
            className: 'col-span-6',
            fieldClassName: 'rounded-lg mt-1 mb-5 block w-full border-gray-300 shadow-sm focus:ring-BrandGreen-600 focus:ring-BrandGreen-600 sm:text-sm'

        }
    ]
}