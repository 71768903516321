import React, { useState } from 'react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-scroll'
import logo from '../assets/OmniSTAN.svg'

const Navbar = ({ open, setOpen, contactOpen, setContactOpen }) => {
  const [nav, setNav] = useState(false)
  const handleClick = () => {
    setNav(!nav)
  }

  return (
    <>
      <div className='w-screen h-[15vh] z-10 bg-Neutral-0 fixed top-0 shadow-nav-shadow px-[6%]'>
        <div className='flex justify-between items-center w-full h-full'>
          <div className='flex items-center'>
            {/* <h1 className='text-3xl font-bold mr-4 sm:text-4xl text-BrandGreen-900'>
              OmniSTAN
            </h1> */}
            <Link
              to='hero'
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className='cursor-pointer'
            >
              <img src={logo} className='w-44' alt='/' />
            </Link>
          </div>
          <div>
            <ul className='hidden md:flex items-center text-lg  text-BrandGreen-900 font-light'>
              <li className='cursor-pointer'>
                <Link
                  to='about'
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  About
                </Link>
              </li>
              <li className='cursor-pointer'>
                <Link
                  to='features'
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Features
                </Link>
              </li>
              <li className='cursor-pointer'>
                <Link
                  to='faqs'
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  FAQs
                </Link>
              </li>
              <li
                className='cursor-pointer'
              >
                <Link
                  to='contact'
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <div className='hidden md:flex pr-4'>
                  <button
                    onClick={() => {
                      setOpen(!open)
                    }}
                    className='bg-gradient-to-r from-BrandGreen-800 to-BrandGreen-500 border-BrandGreen-800 text-Neutral-0 rounded-lg px-8 py-2 text-base h-12 font-medium'
                  >
                    Request a Demo
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <div className='md:hidden' onClick={handleClick}>
            {!nav ? (
              <MenuIcon className='w-5' />
            ) : (
              <div className='border-2 p-1 py-1 rounded-2xl border-BrandGreen-900'>
                {' '}
                <XIcon className='w-5' />
              </div>
            )}
          </div>
        </div>

        <ul
          className={
            !nav
              ? 'hidden'
              : 'absolute right-0 bg-zinc-50 w-full px-8  text-BrandGreen-900 flex flex-col text-center border-2 text-lg z-40'
          }
        >
          <li className=' border-zinc-300 w-full'>
            <Link
              to='hero'
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
              onClick={e => {
                e.preventDefault()
                handleClick()
              }}
            >
              Home
            </Link>
          </li>
          <li className=' border-zinc-300 w-full'>
            <Link
              to='about'
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={e => {
                e.preventDefault()
                handleClick()
              }}
            >
              About
            </Link>
          </li>
          <li className=' border-zinc-300 w-full'>
            <Link
              to='features'
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={e => {
                e.preventDefault()
                handleClick()
              }}
            >
              Features
            </Link>
          </li>
          <li
            className=' border-zinc-300 w-full'
            onClick={e => {
              e.preventDefault()
              handleClick()
            }}
          >
            <Link
              to='contact'
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={e => {
                e.preventDefault()
                handleClick()
              }}
            >
              Contact Us
            </Link>
          </li>
          <li className=' border-zinc-300 w-full'>
            <Link
              to='faqs'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={e => {
                e.preventDefault()
                handleClick()
              }}
            >
              FAQs
            </Link>
          </li>
          <div className='flex flex-col my-4 items-center'>
            <button
              onClick={() => {
                setOpen(!open)
                handleClick()
              }}
              className='bg-gradient-to-r from-BrandGreen-800 to-BrandGreen-500 px-6 py-3 mb-4 text-Neutral-0 rounded-lg w-[100%] text-base'
            >
              Request a Demo
            </button>
          </div>
        </ul>
      </div>
    </>
  )
}

export default Navbar
